<template>
  <div class="kj_index">
    <SwiperCom :list="lbImgs" class="kj_swiper"> </SwiperCom>
    <div class="kj_fuwu">
      <h2>ZOVO DESIGN，为您提供专业工业设计服务</h2>
      <div class="fuwu_text" v-html="fwText"></div>
    </div>
    <div class="kj_case">
      <div class="kj_case_head">
        <a class="head_left">SEE OUR WORK</a>
        <div class="case_head_list">
          <a
            v-for="(item, idx) in zpHead"
            :key="idx"
            :href="'#/kj_works?currentIdx=' + item.id"
            :class="[active == idx ? 'xz_active' : '']"
            @click="toLink(), (currentIdx = item.id)"
            >{{ item.name }}</a
          >
        </div>
      </div>
      <ul class="kj_case_nr">
        <li v-for="(item, idx) in caseDataList" :key="idx">
          <div class="li_l">
            <a
              :href="'#/kj_works?currentId=' + item[0].id"
              class="item"
              @click="toLink()"
            >
              <img :src="item[0].site_images" alt="" />
              <div class="item_mask">
                <div class="item_mask_con">
                  <h2>{{ item[idx].title }}</h2>
                  <h4>{{ item[idx].subtitle }}</h4>
                  <p class="line"></p>
                </div>
              </div>
            </a>
          </div>
          <div class="li_c">
            <a
              :href="'#/kj_works?currentId=' + item[1].id"
              class="item"
              @click="toLink()"
            >
              <img :src="item[1].site_images" alt="" />
              <div class="item_mask">
                <div class="item_mask_con">
                  <h2>{{ item[1].title }}</h2>
                  <h4>{{ item[1].subtitle }}</h4>
                  <p class="line"></p>
                </div>
              </div>
            </a>
            <a
              :href="'#/kj_works?currentId=' + item[2].id"
              @click="toLink()"
              class="item"
            >
              <img :src="item[2].site_images" alt="" />
              <div class="item_mask">
                <div class="item_mask_con">
                  <h2>{{ item[2].title }}</h2>
                  <h4>{{ item[2].subtitle }}</h4>
                  <p class="line"></p>
                </div>
              </div>
            </a>
          </div>
          <div class="li_r">
            <a
              :href="'#/kj_works?currentId=' + item[3].id"
              class="item"
              @click="toLink()"
            >
              <img :src="item[3].site_images" alt="" />
              <div class="item_mask">
                <div class="item_mask_con">
                  <h2>{{ item[3].title }}</h2>
                  <h4>{{ item[3].subtitle }}</h4>
                  <p class="line"></p>
                </div>
              </div>
            </a>
            <a
              :href="'#/kj_works?currentId=' + item[4].id"
              class="item"
              @click="toLink()"
            >
              <img :src="item[4].site_images" alt="" />
              <div class="item_mask">
                <div class="item_mask_con">
                  <h2>{{ item[4].title }}</h2>
                  <h4>{{ item[4].subtitle }}</h4>
                  <p class="line"></p>
                </div>
              </div>
            </a>
          </div>
        </li>
        <a href="#/kj_works" class="case_more" @click="toLink()">
          MORE
          <i class="el-icon-arrow-right"></i>
        </a>
      </ul>
    </div>
    <div
      class="kj_warp"
      :style="{
        backgroundImage: `url(${warpData.serviceGif})`
      }"
    >
      <p>{{ warpData.kjService }}</p>
      <a href="#/kj_service" @click="toLink(55)">
        ZOVO 专业设计
      </a>
    </div>
    <div class="kj_news">
      <div class="kj_news_title">设计资讯 /</div>
      <div class="kj_news_nav">
        <div class="nav_left">
          <img :src="dataMsg.kjImg" alt="" style="width:100%;height:100%" />
        </div>
        <ul class="nav_right">
          <li v-for="(item, idx) in sjzxList" :key="idx">
            <a :href="'#/kj_idea?currentIdx=' + item.id" @click="toLink(57)">
              <h2>{{ item.name }}</h2>
            </a>
            <div class="li_news">
              <a
                v-for="(el, i) in item.news"
                :key="i"
                :href="
                  '#/kj_idea?currentIdx=' + item.id + '&currentId=' + el.id
                "
                @click="toLink(57)"
              >
                <span>{{ el.title }}</span>
                <span>{{ el.create_time }}</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="kj_about">
      <img :src="warpData.kh_img" alt="" />
    </div>
  </div>
</template>

<script>
import SwiperCom from '@/common/Carousel/zzCarousel.vue'
import {
  getPageMsg,
  getXwData,
  getZzCaseList,
  getCaseTypes,
  getBasicMsg
} from '@/api/api'
export default {
  components: { SwiperCom },
  data () {
    return {
      lbImgs: [],
      fwText:
        '行业创新领导者<br>为中国企业创造指数增益，助力成为世界品牌<br>提供设计、打样、量产等全产业链设计服务<br>专注智能装备、医疗产品研发',
      caseDataList: [],
      active: 0,
      currentIdx: '',
      warpData: {},
      dataMsg: {},
      sjzxList: [],
      from: {
        page: 1,
        pagesize: 10,
        type_id: '',
        project: ''
      },
      zpHead: [],
      zpList: []
    }
  },
  methods: {
    getData () {
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          this.from.project = item.data.data.project_id
          this.getZpList()
          this.getPage(id)
          this.getXw(item.data.data.project_id)
        }
      })
    },
    getXw(i){
      getXwData(i).then(res => {
        if (res.data.code == 1) {
          this.sjzxList = res.data.data
        }
      })
    },
    getPage(i){
      getPageMsg(i).then(res => {
            if (res.data.code == 1) {
              this.lbImgs = res.data.data.kjLunbo.lunboList
              this.warpData = res.data.data.kjService
              this.dataMsg = res.data.data.kjMsg
            }
      })
    },
    getZpList () {
      let that = this
      getZzCaseList(this.from).then(res => {
        if (res.data.code == 1) {
          that.zpList = []
          that.zpList = res.data.data.list
          if (that.zpList.length != 0) {
            that.caseDataList = []
            let obj = that.zpList.slice(0, 5)
            let obj2 = that.zpList.slice(5, 10)
            that.caseDataList.push(obj)
            that.caseDataList.push(obj2)
          }
        }
      })
      getCaseTypes(that.from.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: 'ALL',
            id: ''
          })
          this.zpHead = res.data.data
        }
      })
    },
    toLink (i) {
      if (i) {
        sessionStorage.setItem(this.changeData() + 'id', i)
      } else {
        sessionStorage.setItem(this.changeData() + 'id', 54)
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .kj_swiper {
    z-index: 1;
    overflow: hidden;
    .swiper-item {
      height: 100%;
      position: relative;
    }
  }
  .kj_fuwu {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0 30px;
    position: relative;
    z-index: 9;
    h2 {
      letter-spacing: 7.5px;
      font-size: 36px;
      color: #5f6061;
      margin-bottom: 20px;
    }
    .fuwu_text {
      font-size: 14px;
      letter-spacing: 3px;
      color: #333;
      animation: fadeleft 0.8s 0.3s ease both;
      line-height: 2;
    }
  }
  .kj_case {
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    .kj_case_head {
      height: 50px;
      position: relative;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      .head_left {
        letter-spacing: 5px;
        font-size: 26px;
        color: #5f6061;
      }
      .case_head_list {
        display: flex;
        a {
          color: #888889;
          font-size: 14px;
          margin-right: 25px;
        }
        a:hover {
          text-decoration: underline;
          color: #000;
        }
        .xz_active {
          text-decoration: underline;
          color: #000;
          font-size: 18px;
        }
      }
    }
    .kj_case_nr {
      width: 100%;
      font-size: 14px;
      li {
        display: flex;
        .li_l,
        .li_c,
        .li_r {
          height: 900px;
          margin-bottom: 5px;
          width: 31%;
          .item {
            display: block;
            width: 100%;
            position: relative;
            overflow: hidden;
            img {
              transition: all 0.4s ease-in-out;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .item_mask {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              transition: all 0.4s ease-in-out;
              opacity: 0;
              z-index: 1;
              .item_mask_con {
                position: absolute;
                width: 80%;
                left: 10%;
                bottom: 40px;
                z-index: 3;
                h2,
                h4 {
                  color: white;
                  line-height: 1;
                }
                h2 {
                  font-size: 24px;
                  margin-bottom: 20px;
                }
                h4 {
                  font-size: 12px;
                }
                p {
                  height: 1px;
                  width: 35px;
                  background: #fff;
                  display: block;
                  transition: all 0.5s ease-in-out 0.3s;
                  margin-top: 20px;
                }
              }
            }
          }
          .item:hover {
            img {
              transform: scale(1.1);
            }
            .item_mask {
              opacity: 1;
              p {
                width: 50px;
              }
            }
          }
        }
        .li_l .item {
          height: 100%;
        }
        .li_c {
          width: calc(38% - 10px);
          margin: 0 5px;
          a:nth-child(1) {
            margin-bottom: 5px;
            height: 35%;
          }
          a:nth-child(2) {
            height: calc(65% - 5px);
          }
        }
        .li_r {
          a:nth-child(1) {
            margin-bottom: 5px;
            height: calc(65% - 5px);
          }
          a:nth-child(2) {
            height: 35%;
          }
        }
      }
      .case_more {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #b9b9b9;
        width: 300px;
        height: 50px;
        transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        border-radius: 0px;
        text-align: center;
        text-indent: 0px;
        font-size: 13px;
        position: relative;
        margin: 80px auto;
        i {
          font-family: 'simsun';
          font-size: 18px;
          text-indent: -10px;
          line-height: 50px;
          transition: all ease-out 0.3s;
          color: #fff;
          display: inline-block;
          opacity: 0;
        }
      }
      .case_more:after {
        content: '';
        display: block;
        width: 260px;
        height: 42px;
        border: 1px solid #d9d9d9;
        position: absolute;
        bottom: 2px;
        z-index: 2;
        margin-left: 20px;
        transition: all 0.2s ease 0s;
      }
      .case_more:hover {
        color: #333;
        i {
          color: #333;
          text-indent: 10px;
          opacity: 1;
        }
      }
      .case_more:hover:after {
        transform: scale(1.1, 1.1);
        border: 1px solid #e0e0e0;
      }
    }
  }
  .kj_warp {
    margin: 100px 0 60px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 4vw;
      color: #fff;
    }
    a {
      background: #fff;
      color: #444;
      display: block;
      position: absolute;
      left: 50%;
      border: 1px solid #fff;
      bottom: 25%;
      font-size: 0.7vw;
      transition: all 0.3s ease;
      transform: translateX(-50%);
      padding: 1.2vw 2vw;
    }
    a:hover {
      color: #fff;
      border: 1px solid #fff;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .kj_news {
    padding: 0 140px;
    width: calc(100% - 280px);
    .kj_news_title {
      font-size: 30px;
      margin-bottom: 13px;
    }
    .kj_news_nav {
      display: flex;
      justify-content: space-between;
      .nav_left,
      .nav_right {
        width: calc(50% - 50px);
        height: 450px;
      }
      .nav_right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;
        li {
          width: 48%;
          height: 134px;
          margin-top: 10px;
          padding-bottom: 5px;
          border-bottom: 1px solid #e6e6e6;
          overflow: hidden;
          h2 {
            font-size: 16px;
            line-height: 1.2;
            margin-bottom: 7px;
            color: #000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .li_news {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 6px;
            overflow: hidden auto;
            max-height: calc(100% - 20px);
            a {
              display: flex;
              justify-content: space-between;
              width: 100%;
              font-size: 12px;
              line-height: 1.55;
              color: #959595;
              span:nth-child(1) {
                display: inline-block;
                max-width: 67%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            a:hover {
              color: #000;
            }
          }
        }
      }
    }
  }
  .kj_about {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
    }
  }
}
</style>
